<template>
  <div class="pc reserva">
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>  
        </div>

        <header class="p-3" v-if="booking.arrival_date">
            <h1 v-cloak>
                <div @click="routerLink">
                    <i class="fal fa-chevron-left"></i>
                {{ translator.reserva }} {{ booking.reservation }}
                </div>
            </h1>
        </header>

        <div class="container" v-if="booking.arrival_date">
        
            <label>
                {{ translator.propietat }}
            </label>
            {{ booking.lodging_name }}
    

            <div class="row my-3">
                <div class="col-6" v-cloak>
                    <label>
                        {{ translator.arribada }}
                    </label>
                    <div class="data-arribada">{{ booking.arrival_date | transformDate}}</div>
                    <div class="hora-arribada">{{ booking.arrival_time }} <span v-if="booking.arrival_time != ''">h</span></div>
                </div>
                <div class="col-6 border-left" v-cloak>
                    <label v-cloak>
                        {{ translator.sortida }}
                    </label>
                    <div class="data-sortida">{{ booking.departure_date | transformDate}}</div>
                    <div class="hora-sortida">{{ booking.departure_time }} <span v-if="booking.departure_time != ''">h</span></div>
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <label v-cloak>
                        {{ translator.nits }}
                    </label>
                </div>
                <div class="col-9 nits" v-if="booking.nights == 1" v-cloak>
                    {{ booking.nights }} {{ translator.nit | toLower }}
                </div>
                <div class="col-9 nits" v-else v-cloak>
                    {{ booking.nights }} {{ translator.nits | toLower }}
                </div>
            </div>
            <div class="row" v-if="booking.agency_name" v-cloak>
                <div class="col-3">
                    <label v-cloak>
                        {{ translator.origen }}
                    </label>
                </div>
                <div class="col-9 agencia" v-cloak>
                    {{ booking.agency_name }} <span v-if="booking.agency_reservation">- ID {{ booking.agency_reservation }}</span>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-3">
                    <label v-cloak>
                        {{ translator.telefon }}
                    </label>
                </div>
                <div class="col-9" v-cloak>
                    <a :href="'tel:' + booking.customer_phone" class="telefon">{{ booking.customer_phone }}</a>
                </div>
            </div>

            <div class="mb-3">

                <routerLink class="input-group mb-2 mr-sm-2" 
                    :class="
                    booking.customer_address == '' ||
                    booking.customer_city == '' ||
                    booking.customer_country == '' ||
                    booking.customer_email == '' ||
                    booking.customer_first_name == '' ||
                    booking.customer_last_name == '' ||
                    booking.customer_phone == '' ||
                    booking.customer_tin == '' ||
                    booking.customer_zip == '' 
                    ? '' : 'completed' 
                    " 
                    :to="{ name: 'BookingDetails', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}">
                    <div class="input-group-prepend">
                        <div class="input-group-text" v-cloak>
                            <i v-if="!booking._registerDocument" 
                                class="fas fa-exclamation-triangle">
                            </i> 
                            <i v-else class="fas fa-check-circle"></i> 

                            {{ translator.reserva }}
                        </div>
                    </div>
                    <div class="input-pasos-reserva input-group-text d-flex justify-content-between" v-cloak>
                        <span>{{ translator.dadesreserva }}</span>
                        <i class="far fa-long-arrow-right"></i> 
                    </div>
                </routerLink>

                <!-- Hostes registrats -->
                <routerLink :to="{ name: 'BookingGuests', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}" class="input-group mb-2 mr-sm-2" :class="{ completed: booking.registered_guests != 0 && booking.guestsCompleted, almost: booking.mandatory_signatures_contract == 'true' && !controlAdultWithSign && booking.guestsCompleted }">
                    <div class="input-group-prepend">
                        <div class="input-group-text" v-cloak>
                            
                            <i v-if="booking.mandatory_signatures_contract == 'true' && !controlAdultWithSign && booking.guestsCompleted" class="fas fa-check-circle icono-completo" style="color:var(--colores-amarillo-2);"></i>
                            <i v-else-if="booking.registered_guests == '0' || !booking.guestsCompleted" class="fas fa-exclamation-triangle"></i>
                            <i v-else class="fas fa-check-circle"></i>
                            {{ translator.hostes }}
                        </div>
                    </div>
                    <div class="input-pasos-reserva input-group-text d-flex justify-content-between" v-cloak>
                        <span>{{ booking.registered_guests }}/{{ booking.sumAdultsChildren }} {{ translator.registrats | toLower }}</span>
                        <i class="far fa-long-arrow-right"></i> 
                    </div>
                </routerLink>

                <!-- Pagament -->
                <router-link v-if="booking.visible_checkin == 'true'" :to="{ name: 'BookingPayment', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}" class="input-group mb-2 mr-sm-2" v-bind:class="{ completed: booking.paymentCompleted && bookingPayments.deposit_paid == 'true', almost: booking.paymentCompleted && bookingPayments.deposit_paid == 'false'}">
                    <div class="input-group-prepend">
                        <div class="input-group-text" v-cloak>
                            <i v-if="!booking.paymentCompleted" class="fas fa-exclamation-triangle"></i> 
                            <i v-else-if="booking.paymentCompleted && bookingPayments.deposit_paid == 'false'" class="fas fa-check-circle" style="color:var(--colores-amarillo-2);"></i>
                            <i v-else-if="booking.paymentCompleted && bookingPayments.deposit_paid == 'true'" class="fas fa-check-circle"></i>
                            {{ translator.pagament }}
                        </div>
                    </div>
                    <div class="input-pasos-reserva input-group-text d-flex justify-content-between" v-cloak>
                        <span v-if="booking.paymentCompleted && bookingPayments.deposit_paid == 'true'">{{ translator.pagat }}</span>
                        <span v-else-if="booking.paymentCompleted && bookingPayments.deposit_paid == 'false'">{{ translator.diposit }}</span>
                        <span v-else>{{ booking.pending_payment_status }}</span>
                        <i class="far fa-long-arrow-right"></i>
                    </div>
                </router-link>

                <!-- contracte -->
                <router-link :to="{ name: 'BookingContract', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}" class="input-group mb-2 mr-sm-2" v-bind:class="{ completed: booking.contractCompleted }">
                    <div class="input-group-prepend">
                        <div class="input-group-text" v-cloak>
                            <i v-if="!booking.contractCompleted" class="fas fa-exclamation-triangle"></i> 
                            <i v-if="booking.contractCompleted" class="fas fa-check-circle"></i>
                            {{ translator.contracte }}
                        </div>
                    </div>
                    <div class="input-pasos-reserva input-group-text d-flex justify-content-between" v-cloak>
                        
                        <span v-if="booking.contractCompleted">{{ translator.signat }}</span>
                        <span v-if="!booking.contractCompleted">{{ translator.nosignat }}</span>
                        <i class="far fa-long-arrow-right"></i>
                    </div>
                </router-link>
                <!-- //// -->
                <!-- \\ Ver con check-in confirmado \\ -->
                <div v-if="booking.checkinCompleted" class="input-group mb-2 mr-sm-2" v-bind:class="{ completed: booking.checkinCompleted }">
                    <div class="input-group-prepend">
                        <div class="input-group-text" v-cloak>
                            <i class="fas fa-check-circle"></i>
                            {{ translator.checkin }}
                        </div>
                    </div>
                    <div class="input-pasos-reserva d-flex justify-content-between" v-cloak>
                        <span v-if="booking.signed_contract">{{ translator.completat }}</span>
                    </div>
                </div>
                <!-- \\\\ -->

                <!-- // Ver con check-out confirmado // -->
                <div v-if="booking.status > '6'" class="input-group mb-2 mr-sm-2" v-bind:class="{ completed: booking.status > 6}">
                    <div class="input-group-prepend">
                        <div class="input-group-text" v-cloak>
                            <i class="fas fa-check-circle"></i>
                            {{ translator.checkout }}
                        </div>
                    </div>
                    <div class="input-pasos-reserva d-flex justify-content-between" v-cloak>
                        <span>{{ translator.completat }}</span>
                    </div>
                </div>
                <!-- //// -->

                <div v-if="errorFormBooking.length > 0" class="alert alert-danger mt-4" role="alert">
                    <p>
                        {{ translator.faltacompletardadesreserva }}
                    </p>
                </div>

                <!-- Botón ckeck-in -->
                <div v-if="!booking.checkinCompleted">
                    <a v-if="
                        booking._registerDocument &&
                        ( booking.registered_guests >= booking.adults || booking.mandatory_guests_at_check_in == 'false' ) && 
                        ( booking.contractCompleted || booking.mandatory_signature_at_check_in == 'false' ) &&
                        ( booking.paymentCompleted || booking.mandatory_payment_at_check_in == 'false' )
                        " 
                        @click.prevent.self="validateFormBooking('checkIn')" class="btn btn-primary" v-cloak>{{ translator.checkin }}</a>
                    <a v-else class="btn btn-primary disabled" disabled v-cloak>{{ translator.checkin }}</a>
                </div>

                <button v-if="booking.checkinCompleted && !booking.checkOutInTime && parseInt(booking.status) <= 6" class="btn btn-primary" disabled >{{ translator.checkout }}</button>
                <button v-else-if="booking.checkinCompleted && booking.checkOutInTime && parseInt(booking.status) <= 6" @click.prevent.self="checkOut" class="btn btn-primary">{{ translator.checkout }}</button>

            </div>

            <div v-if="booking.arrival_remarks != ''">
                <label v-cloak>
                    {{ translator.observacionsentrada }}
                </label>
                <p v-cloak>
                    {{ booking.arrival_remarks }}
                </p>
            </div>
            <div v-if="booking.departure_remarks != ''">
                <label v-cloak>
                    {{ translator.observacionssortida }}
                </label>
                <p v-cloak>
                    {{ booking.departure_remarks }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: 'Booking',
    computed: {
        ...mapState("utils", ["user", "translator"]),
        ...mapState("checkin", ['booking', 'bookingGuests', 'bookingPayments']),
    },
    watch: {
        translator() {
            this.$store.dispatch( "checkin/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
        },
        booking() {
            this.isPreLoad = false;
            this.checkStatusBooking();
            this.$store.dispatch("checkin/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.booking.reservation });
            if (this.booking.mandatory_signatures_contract) {
                this.$store.dispatch("checkin/getHostes",  { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.booking.reservation });
            }
        },
        bookingGuests() {
            let adults = 0;
            let firms = 0;
            this.bookingGuests.forEach(guest => {
                guest._isAdult ? adults++ : '';
                guest._isAdult && guest._isSign ? firms++ : '';
            });

            firms >= adults ? this.controlAdultWithSign = true : '';
        }
    },
    data() {
        return {
            isPreLoad: false,
            routeList: sessionStorage.getItem('listado'),
            controlAdultWithSign: false,
            errorFormBooking: []
        };
    },
    async created() {
        this.isPreLoad = true; 
        try {
            this.translator 
                ? await this.$store.dispatch( "checkin/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId })
                : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
        
        } catch (error) {
            console.error(error);
        }
    },

    methods: {
        checkStatusBooking() {
            this.booking.customer_address == '' ||
            this.booking.customer_city == '' ||
            this.booking.customer_country == '' ||
            this.booking.customer_email == '' ||
            this.booking.customer_first_name == '' ||
            this.booking.customer_last_name == '' ||
            this.booking.customer_phone == '' ||
            this.booking.customer_tin == '' ||
            this.booking.customer_zip == '' 
            ? this.booking._registerDocument = false 
            : this.booking._registerDocument = true; 
        },

        validateFormBooking(validar) {
            this.checkStatusBooking()
            this.errorFormBooking = [];
            let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

            this.booking._registerDocument ? '' : this.errorFormBooking.push(this.translator.faltacompletardadesreserva);
            regEmail.test(this.booking.customer_email) ? '' : this.errorFormBooking.push(this.translator.faltaemail);
            this.booking.adults === '' || this.booking.adults == 0 ? this.errorFormBooking.push(this.translator.faltapersones) : '';

            if (validar === 'checkIn') {
                this.errorFormBooking.length === 0 ? this.checkIn() : ''
            } else {
                this.errorFormBooking.length === 0 ? this.getBookingSave() : ''
            }
        },

        async checkIn() {
            try {
                this.isPreLoad = true;
                await this.$store.dispatch( "checkin/checkIn", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, ip: '' });
                this.$toast.success(`${this.translator.checkin} ${this.translator.completat}`);
            } catch (error) {
                console.error( error );
            } finally {
                this.isPreLoad = false;
            }
        },

        async checkOut() {
            try {
                this.isPreLoad = true;
                await this.$store.dispatch( "checkin/checkOut", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, ip: '' });
                this.$toast.success(`${this.translator.checkout} ${this.translator.completat}`);
            } catch (error) {
                console.error( error );
            } finally {
                this.isPreLoad = false;
            }
        },
        routerLink() {


            this.$router.push({ name: this.routeList, query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') }})
        }
    },
    
    filters: {
    toLower(value) {
        if (!value) return "";
        value = value.toString();
        return value.toLowerCase();
    },
    transformDate(value) {
      if (!value) return '';
      const backendDate = value.split('-');
      return `${backendDate[2]}-${backendDate[1]}-${backendDate[0]}`;
    },
  },
}
</script>

<style scoped>
.reserva, .reserva header {
    background: var(--white);
    font-size: 0.9rem;
}

</style>